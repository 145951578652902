.logo
	line-height 50px
	margin-right 10px
	opacity 0.8
	font-family 'VAGRounded TL', Arial, sans-serif
	font-size 35px
	color $orange-text
	text-decoration none

	&:hover
		opacity 1

.logo__name_d
	display inline-block

.logo__name_m
	display none

@media (max-width 768px)
	.logo
		margin-right 0
		font-size 30px
		vertical-align top
		margin-top -2px
		margin-bottom 2px
