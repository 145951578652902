.quest-type
  margin-bottom 30px
  color #fff
  &-link
    color #fff
  &-wrap
    position relative
    display block
    min-height 150px
    padding-bottom 66.66%
    .btn-wrap
      position relative
      bottom 0
      display block
      width 100%
  &-title
    position absolute
    width 100%
    top 0
    @extend .muller
    font-size 26px
    background linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 60%, rgba(0,0,0,0) 100%)
    padding 20px
  &-hint
    position absolute
    width 100%
    bottom 0
    background linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 60%, rgba(0,0,0,0) 100%)
    padding 15px 20px
    color $gray-light
    font-size 16px
    
  img
    width 100%
    display block
    min-height 100px
    position absolute

@media (max-width: 768px)
  .quest-type
    max-width 600px
    margin-left auto
    margin-right auto