.icon
	line-height 1
	font-size 20px
	display inline-block
	vertical-align top
	margin-right 10px

.icon_white
	fill #fff

.icon_orange
	height 24px
	margin-left 0
	fill $orange

.icon-review
	position relative
	width 27px
	height 18px
	line-height 18px
	background-color $orange
	color #fff
	font-size 13px
	font-weight 700
	text-align center
	border-radius 3px
	letter-spacing 1px
	&:after
		content ''
		position absolute
		top 100%
		left 4px
		border-style solid
		border-width 2px 3px 2px 3px
		border-color $orange transparent transparent $orange
