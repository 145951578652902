.tooltip
  &-inner
    background rgba($orange, .9)
    white-space nowrap
    color #000
  &.top .tooltip-arrow
      border-top-color rgba($orange, .9)
  &.bottom .tooltip-arrow
      border-top-color rgba($orange, .9)
  &.left .tooltip-arrow
    border-left-color rgba($orange, .9)
  &.right .tooltip-arrow
    border-right-color rgba($orange, .9)
