.text-content
  h3, .h3
    color $orange-text
  p
    font-size 16px
  ul, ol
    font-size 16px
    padding-left 20px
    li
      margin 20px 0 20px 20px
      line-height 1.5
  ul
    list-style inherit
  ol
    list-style decimal
  img
    max-width 100%
    height auto