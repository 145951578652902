.filter-wrap
  background #fff
  padding 15px 10px
  margin 0 0 15px
  border 1px solid $gray-light
  border-radius 4px

.filter-index-wrap
  margin-top 30px
  margin-bottom -15px
