/*defaults*/

html
	height 100%

body
	height 100%
	font-size 14px
	font-family Arial, sans-serif
	font-weight 400
	line-height 1.2
	background #f5f5f5

.all-container
	min-height 100%
	max-width 1920px
	margin 0 auto
	margin-bottom -59px // footer height
	padding-top 50px // nav height
	box-shadow 0 0 5px rgba(0,0,0,0.5)
	overflow-x hidden
	&:after
		content ''
		display block
		height 59px // footer height

.relative
	position relative

.fz0
	font-size 0

.tac
	text-align center

.block
	margin 30px auto

.block-top
	margin-top 30px

.block-bottom
	margin-bottom 30px

.fl
	float left

.fr
	float right

.ib
	display inline-block
	vertical-align top

.im
	display inline-block
	vertical-align middle
	line-height 1.2

.db
	display block !important

.dn
	display none

.visible
	opacity 1

.img
	display block
	width 100%

.video-container {
	position:relative;
	padding-bottom:56.25%;
	height:0;
	overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}