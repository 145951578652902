@font-face
  font-family: "iconfont"
  src: url('../fonts/iconfont.eot');
  src: url('../fonts/iconfont.eot?#iefix') format('eot'),
    url('../fonts/iconfont.woff2') format('woff2'),
    url('../fonts/iconfont.woff') format('woff'),
    url('../fonts/iconfont.ttf') format('truetype'),
    url('../fonts/iconfont.svg#iconfont') format('svg')

  font-weight: normal
  font-style: normal

.font-icon
  &:before
    font-family: "iconfont"
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    
    font-style: normal
    font-variant: normal
    font-weight: normal
    
    text-decoration: none
    text-transform: none


.font-icon-attention-sign
  @extend .font-icon
  
  &:before
    content: "\E001"

.font-icon-call-answer
  @extend .font-icon
  
  &:before
    content: "\E002"

.font-icon-coin-stack
  @extend .font-icon
  
  &:before
    content: "\E003"

.font-icon-email
  @extend .font-icon
  
  &:before
    content: "\E004"

.font-icon-hourglass
  @extend .font-icon
  
  &:before
    content: "\E005"

.font-icon-kids
  @extend .font-icon
  
  &:before
    content: "\E006"

.font-icon-left-arrow
  @extend .font-icon
  
  &:before
    content: "\E007"

.font-icon-pacifier
  @extend .font-icon
  
  &:before
    content: "\E008"

.font-icon-people
  @extend .font-icon
  
  &:before
    content: "\E009"

.font-icon-reviews
  @extend .font-icon
  
  &:before
    content: "\E00A"

.font-icon-right-arrow
  @extend .font-icon
  
  &:before
    content: "\E00B"

.font-icon-star-filled
  @extend .font-icon
  
  &:before
    content: "\E00C"

.font-icon-star
  @extend .font-icon
  
  &:before
    content: "\E00D"

.font-icon-balloon
  @extend .font-icon
  
  &:before
    content: "\E00E"

.font-icon-birthday-cake
  @extend .font-icon
  
  &:before
    content: "\E00F"

.font-icon-bubbles
  @extend .font-icon
  
  &:before
    content: "\E010"

.font-icon-cup
  @extend .font-icon
  
  &:before
    content: "\E011"

.font-icon-gift
  @extend .font-icon
  
  &:before
    content: "\E012"

.font-icon-good-mood-emoticon
  @extend .font-icon
  
  &:before
    content: "\E013"

.font-icon-home
  @extend .font-icon
  
  &:before
    content: "\E014"

.font-icon-photo-camera
  @extend .font-icon
  
  &:before
    content: "\E015"

.font-icon-pie-chart
  @extend .font-icon
  
  &:before
    content: "\E016"

.font-icon-sales
  @extend .font-icon
  
  &:before
    content: "\E017"

.font-icon-sun
  @extend .font-icon
  
  &:before
    content: "\E018"
