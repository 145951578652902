.link
	display inline-block
	height 50px
	line-height 50px
	padding-right 15px
	padding-left 15px
	font-size 15px
	cursor pointer
	text-decoration underline
	&:hover
		color $orange-text
		text-decoration none

h2 + .link
	height auto
	line-height 43px
	margin-top 30px
	margin-left 30px

@media only screen and (max-width: 768px)
	h2 + .link
		margin-top 0
		margin-left 0
		padding-left 0