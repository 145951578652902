.review
  margin-top 30px
  &:first-child
    margin-top 0

.review__author
  width 225px
  padding-top 11px
  padding-left 5px
  padding-right 25px
  text-align center
  font-size 18px
  font-weight 700

.review__teamInfo
  font-weight normal
  font-size 14px

.review__body
  position relative
  margin-left 225px
  padding 0 40px 20px
  font-size 16px
  line-height 1.6
  color #212121
  background #e0e0e0
  border-radius 5px
  min-height 88px
  p
    padding-top 20px
    margin 0
  &:before
    content ''
    position absolute
    top 22px
    right 100%
    border-style solid
    border-width 10px
    border-color #e0e0e0 #e0e0e0 transparent transparent
  .review__title
    margin-bottom -10px
    font-weight bold
    a
      color $black

.review__grade
  margin-top 5px

.star
  display inline-block
  vertical-align top
  width 19px
  height 19px
  margin-right 1px
  background url(../images/star.png) no-repeat 50% 50%
  &.active
    background url(../images/star-active.png) no-repeat 50% 50%

.reviews
  h2
    margin-top 0
    margin-right 30px
    & + a.btn
      margin-top 0
      margin-left 0

@media only screen and (max-width: 768px)
  .review__author
    float none
    padding 5px
    width 100%

  .review__body
    margin-top 15px
    margin-left 0
    &:before
      display none
    &:after
      content ''
      position absolute
      left 50%
      bottom 100%
      margin-left -10px
      border-style solid
      border-width 10px
      border-color transparent transparent #e0e0e0 transparent
