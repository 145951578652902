.nav
  position fixed
  max-width 1920px
  margin 0 auto
  z-index 5
  top 0
  left 0
  right 0
  background #fff
  box-shadow 0 0 3px rgba(0, 0, 0, 0.15)
  transform translate3d(0, 0, 0)
  &__btn
    display inline-block
    width 45px
    height 45px
    background $orange
    border-radius 3px
    text-align center
    font-size 38px
    line-height 56px
    color #fff
    text-decoration none
    vertical-align top
    margin-top 3px
    &:hover
      color #fff
      background $orange-dark
    &_request
      font-size 28px
      line-height 38px


  .link
    color $black

  .phone
    line-height 50px
    font-size 18px
    padding 0 30px
    &_d
      display inline-block
    &_m
      display none
    a
      color #000
      font-weight bold
      text-decoration none
      &:hover
        color $orange-text

.hamburger
  display none
  position absolute
  right 3px
  top 3px
  z-index 5
  width 45px
  height 45px
  border 3px solid $orange
  border-radius 3px
  background #fff
  cursor pointer
  transition 0.3s
  &:before,
  &:after
    content ''
    position absolute
    left 6px
    right 6px
    height 3px
    background $orange
    transition 0.2s
  &:before
    top 9px
  &:after
    bottom 9px

.hamburger_opened
  .hamburger__inner
    opacity 0
  &:before
    top 50%
    margin-top -1.5px
    transform rotate(45deg)
  &:after
    bottom auto
    top 50%
    margin-top -1.5px
    transform rotate(-45deg)

.hamburger__inner
  position absolute
  left 6px
  right 6px
  top 50%
  height 3px
  margin-top -1.5px
  background $orange
  transition 0.2s

@media (max-width 768px)
  .hamburger
    display block

  .nav__container
    .nav__item_hm
      display none
      &.active
        display block

  .nav__container
    float none

  .nav__container_first
    display inline-block
    width 100%

  .nav
    &__btn_request
      position absolute
      right 55px
      z-index 2
    .phone
      padding 0 15px
      &_d
        display none
      &_m
        display inline-block
        position absolute
        right 55px
        z-index 1