.dropdown
	position relative
	font-size 15px
	cursor pointer
	transition 0.2s
	white-space nowrap

.dropdown__list
	display none
	position absolute
	z-index 2
	top 100%
	left 0
	padding 0 15px 15px
	background #fff
	box-shadow 0 10px 10px 0 rgba(0,0,0,0.3)
	text-align left
	cursor default
	opacity 0
	transform scale(0,0)
	transform-origin 50% 0 0
	transition 0.2s
	&.active
		opacity 1
		transform scale(1,1)

.dropdown__list_catalog
	list-style-position inside
	column-count 2
	left auto
	right -10px

.dropdown__chosen
	position relative
	padding 16px 15px
	transition 0.2s
	&:hover
		color $orange-text
	&:after
		content ''
		position absolute
		top 50%
		border-style solid
		border-width 4px 4px 0 4px
		border-color #000 transparent transparent transparent
		margin-top -2px
		margin-left 5px
		transition 0.2s
	&:hover:after
		border-color $orange-text transparent transparent transparent

.dropdown__link
	display block
	height auto
	&:hover
		color $orange-text
	&.active
		pointer-events none
		color #9f9f9f

.dropdown__item
	line-height 2
	.link
		line-height 2
		height auto

.dropdown__text_hd
	display none

@media (max-width 768px)

	.dropdown__chosen_city
		padding-left 5px

	.dropdown__list_catalog
		width 100%
		left 0
		position relative
		box-shadow none
		display block
		opacity 1
		transform none
		border-bottom 1px solid $gray-light
		margin-bottom 0
		z-index 1
		column-count 1

	.dropdown__chosen_catalog
		font-weight bold
		&:after
			display none

	.dropdown__list_catalog .dropdown__item .link
		padding-left 0

	.dropdown__text_hd
		display inline