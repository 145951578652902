.swiper-pagination-bullet
  border 2px solid #fff
  background none
  width 12px
  height 12px
  opacity 1
  transition 0.2s

.swiper-pagination-bullet-active
  background #fff
  transform scale(1.3, 1.3)

.swiper-button-prev,
.swiper-button-next
  background #fff
  width 60px
  height 60px
  font-size 220%
  line-height 60px
  text-align center
  margin 0
  margin-top -30px
  color $gray

.swiper-button-prev
  left 0
  @extend .font-icon-left-arrow

.swiper-button-next
  right 0
  @extend .font-icon-right-arrow

@media only screen and (max-width: 900px)
  .swiper-button-prev
    transform scale(0.7,0.7)
    transform-origin 0 50% 0
  .swiper-button-next
    transform scale(0.7,0.7)
    transform-origin 100% 50% 0

@media (max-width: 768px)
  .swiper-button-prev,
  .swiper-button-next
    display none