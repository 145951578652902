footer
  max-width 1920px
  margin 0 auto
  line-height 58px
  border-top 1px solid #ddd
  background #efefef
  font-size 14px
  color #727272
  p
    margin 0
    line-height 58px
    font-size 14px
  a
    color inherit
    text-decoration none
    &:hover
      text-decoration underline
  ul
   margin-bottom 0

.footer__item
  margin 0 10px

.footer__link
  display block
  &:hover
    text-decoration underline

.footer-contacts
  line-height 1.5
  float left
  text-align left
  margin 18px 0

a[href^="mailto"]
  color #337ab7

@media only screen and (max-width: 1000px)
  footer
    line-height 30px
    font-size 13px
    p
      line-height 30px
      font-size 13px
    .footer__item
      margin 0 5px
    .fl,
    .fr
      float none