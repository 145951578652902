$black = #000;

$orange-text = #ff5e57;
$orange-dark = darken($orange-text, 10%);
$orange = lighten($orange-text, 5%);

$gray-light = #eee;
$gray = #8b8b8b;
$gray-dark = #5b5b5b;

$link-color = $orange-text
$link-hover-color = $orange-dark;

.white
  color #fff

.colored
  color $orange-text