hr
  margin-top 20px
  margin-bottom 20px
  border 0
  border-top 1px solid $gray-light

.error-summary
  color #a94442
  background #fdf7f7
  border-left 3px solid #eed3d7
  padding 10px 20px
  margin 0 0 15px 0
  ul
    list-style square
    padding-left 20px

.items-count
  color $orange
  font-size 60px
  margin-top 20px

.items-count-label
  font-size 18px

@media only screen and (max-width: 1200px)
  .items-count,
  .items-count-label,
  .index-title
    text-align center