.quest__slider
  width 65%

.quest__description
  width 35%
  .item-properties
    font-size 16px
    li
      margin 15px 0

.quest__inner
  position relative
  min-height 600px
  max-width 485px
  padding 35px 30px 0 30px
  font-size 15px
  h1
    margin-top 0
    margin-bottom 25px
    font-size 36px
    font-weight 400

.quest-list
  margin-top 20px
  li
    position relative
    margin-top 13px
    font-size 16px
  .icon
    color $orange-text

.items-view-tabs
  margin 0 -5px 10px

.quest-info
  background #e0e0e0
  border-radius 5px
  text-align center
  margin-top 30px
  ul
    padding 22px 5px
    margin-bottom 0
  li
    margin-bottom 5px
  .btn
    margin 0
  .item-company-link
    a
      color #0084ff

.quest-images-container
  width 100%
  .quest-image-slide
    text-align center
    font-size 18px
    background #fff no-repeat 50% 50%
    background-size cover
    display flex
    justify-content center
    align-items center

.items-page
  .breadcrumbs
    position absolute
    margin 0 15px
    opacity 0.9

@media (max-width: 767px)
  .quest-images-container
    height: 250px
  .items-page
    .breadcrumbs
      position relative
      margin 0 -15px
      opacity 1
      border 0

  .quest-list .icon
    display block
    float left

@media (min-width: 768px) and (max-width: 991px)
  .quest-images-container
    height: 300px

@media only screen and (max-width: 992px)
  .quest__slider,
  .quest__description
    float none
    width 100%

  .quest__description
    max-width 740px
    margin 0 auto

  .quest__slider
    .slide
      padding 0
      min-height 380px !important

  .quest__inner
    min-height 0
    padding 20px 15px 0 15px

  .quest-info
    margin-top 30px

@media (min-width: 992px)
  .quest-images-container
    height: 500px

@media (min-width: 1200px)
  .quest-images-container
    height: 600px