.map-item-wrap
  width 300px
  position relative

.map-item-image
  width 100%
  display block

.map-item-info
  display block
  background #ececec
  color $black
  padding 5px 10px

  .quest-people,
  .quest-price,
  .im
    line-height 1.5

@media only screen and (max-width: 768px)
  .map-item-wrap
    width 200px