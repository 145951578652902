.schedule
  position: relative
  margin-bottom: 30px

  &.loading
    height 400px
    line-height 400px
    text-align center
    &:after
      content: 'Загрузка расписания...';

  .schedule-info {
    display: inline-block;
  }

.schedule__day
	display flex
	flex-wrap wrap
	align-items space-between
	padding 15px 15px 5px
	transitions 0.2s
	&:hover
		background #fff

.schedule__date
	width 160px
	font-size 24px
	font-weight 700

.schedule__day-of-week
	margin-bottom 10px
	font-size 14px
	font-weight 400

.schedule__times
	width calc(100% - 160px)

.schedule__time
	display inline-block
	vertical-align top
	text-align center
	margin-right 10px
	.btn
		margin 0
		padding 0 16px
		color #fff
		background #f0ad4e
		&:hover,
		&_active
			background transparent
			color #000

.schedule__price
	margin-top 5px
	margin-bottom 10px
	font-size 14px
	color #4f4f4f

.schedule__controls
	margin-top 30px
	padding 0 15px
	font-size 16px
	color $orange-text

.schedule__arrow
	cursor pointer
	&.not-active
		pointer-events none
		color #d2d2d2

.booked,
.past
	pointer-events none
	color #b7b7b7
	margin-bottom 16px
	.btn
		color #b7b7b7
		border-color #eee
		background #eee
	.schedule__price
		opacity 0

@media only screen and (max-width: 992px)
  .schedule__times
    float left
    width 100%
	.schedule__times .booking-btn
      margin-top 5px
      padding 0 10px

@media only screen and (max-width: 480px)
	.schedule__arrow
      max-width: 49%;
      font-size: 12px;
