.article-caption
	margin-top 20px
	&:first-child
		margin-top 10px

.article-link
	font-size 20px
	color $orange-text
	text-decoration underline
	&:hover
		text-decoration none