.btn
	position relative
	display inline-block
	height 42px
	line-height 38px
	margin 5px
	padding 0 35px
	background transparent
	border 2px solid $orange
	border-radius 5px
	cursor pointer
	text-align center
	font-size 15px
	color #000
	transition 0.2s
	outline none
	white-space nowrap
	text-overflow ellipsis
	text-decoration none
	&:hover,
	&_active
		color #fff
		background $orange
	&:active
		top 1px
	&_big
		height 54px
		line-height 50px
		padding 0 25px
		font-size 16px
		font-weight 700
		color #fff
	&_background
		width 100%
		padding 0
		height 46px
		line-height 42px
		margin 0
		background $orange
		border-radius 0
		font-size 16px
		font-weight 700
		color #fff
		&:hover
			background $orange-dark
			border-color $orange-dark
	&_big_background
		width 100%
		height 54px
		margin 0
		line-height 50px
		background $orange
		font-size 20px
		font-weight 700
		color #fff
		&:hover
			background $orange-dark
			border-color $orange-dark

.btn-container
	margin 10px -5px -10px

.btn-flex
	display flex
	justify-content space-between
	text-align justify
	text-align-last justify
	flex-wrap wrap

h2 + .btn
	margin-top 30px
	margin-left 30px

@media only screen and (max-width: 1200px)
	.btn
		padding 0 15px

@media only screen and (max-width: 992px)
	.btn
		padding 0 8px

@media only screen and (max-width: 768px)
	h2 + .btn
		margin-top 0
		margin-left 0
	.btn-flex
		justify-content flex-start

@media only screen and (max-width: 480px)
	.btn
		padding 0 4px
		font-size 18px