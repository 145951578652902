@font-face {
  font-family: 'Foo';
  src: url('../fonts/Foo-Regular.eot');
  src: url('../fonts/Foo-Regular.eot?#iefix') format('embedded-opentype'),
          url('../fonts/Foo-Regular.woff') format('woff'),
          url('../fonts/Foo-Regular.ttf') format('truetype'),
          url('../fonts/Foo-Regular.svg#Foo-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muller';
  src: url('../fonts/MullerExtraBold.eot');
  src: url('../fonts/MullerExtraBold.eot?#iefix') format('embedded-opentype'),
          url('../fonts/MullerExtraBold.woff') format('woff'),
          url('../fonts/MullerExtraBold.ttf') format('truetype'),
          url('../fonts/MullerExtraBold.svg#MullerExtraBoldDEMO') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VAGRounded TL';
  src: url('../fonts/VAGRoundedTL-Regular.eot');
  src: url('../fonts/VAGRoundedTL-Regular.eot?#iefix') format('embedded-opentype'),
          url('../fonts/VAGRoundedTL-Regular.woff2') format('woff2'),
          url('../fonts/VAGRoundedTL-Regular.woff') format('woff'),
          url('../fonts/VAGRoundedTL-Regular.ttf') format('truetype'),
          url('../fonts/VAGRoundedTL-Regular.svg#VAGRoundedTL-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.muller
  font-family Muller, Arial, 'sans-serif'

h1, .h1
  @extend .muller
  margin-top 45px
  margin-bottom 22px
  font-size 48px
  font-weight 700
  line-height 1

h2, .h2
  @extend .muller
  margin-top 30px
  margin-bottom 15px
  font-size 36px

h3, .h3
  @extend .muller
  margin-top 25px
  margin-bottom 12px
  font-size 20px

p
  margin-bottom 15px
  line-height 1.5

h3 + p, .h3 + p
  margin-top 10px

@media only screen and (max-width: 768px)
  h1, .h1
    margin-top 35px
    margin-bottom 17px
    font-size 33px

  h2, .h2
    display block !important
    margin-top 20px
    margin-bottom 10px
    font-size 25px

  h3, .h3
    margin-top 15px
    margin-bottom 7px
    font-size 18px

  p
    margin-bottom 7px

@media only screen and (max-width: 480px)
  h1, .h1
    font-size 25px