.pick-block
  min-height 400px
  position relative
  box-sizing border-box
  padding-top 125px
  h1
    text-align center
    color #000
    position relative
    z-index 2
    width: 555px;
    margin: 0 auto;
    font-size 40px
  &-btn-wrap
    margin 70px auto 50px
    width 360px
    position relative
    z-index 2

.pick-block-bg-mb
  display none

.pick-block-bg
  display block
  position absolute
  z-index 0
  &_girl
    top 19px
    left 31px
    width 200px
    height 353px
    background url("../images/bg-girl.png") no-repeat
    background-size contain
  &_boy
    top 28px
    right -3px
    width 266px
    height 356px
    background url("../images/bg-boy.png") no-repeat
    background-size contain


@media (max-width: 768px)
  .pick-block
    padding-top 63px
    &-btn-wrap
      width 100%
      margin-top 30px
      a
        font-size 16px

  .pick-block h1
    font-size 27px
    width 100%
    margin-bottom 25px

  .pick-block-bg
    display none

  .pick-block-bg-mb
    display block
    background url("../images/bg-boy-mobile.png") no-repeat top center
    background-size contain
    width 290px
    height 180px
    margin 0 auto