label
  position relative
  display block
  color #777
  margin-bottom 5px
  font-weight 700

.form__btn
  margin-top 20px !important

.error
  border-color #da0303 !important
  box-shadow 0 0 10px #da0303

.radio-inline, .checkbox-inline
  line-height 1.6

input::placeholder,
textarea::placeholder {
  color: $gray;
}