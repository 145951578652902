.items-list
  display flex
  flex-wrap wrap
  flex-direction row
  justify-content flex-start

.quest-item
  margin-bottom 30px
  text-align left

.quest-item__actions
  position absolute
  bottom 50px
  li
    position relative
    display inline-block
    background $orange-dark
    padding 10px 0 10px 20px
    &:after
      content ''
      position absolute
      border 1.3em solid $orange-dark
      top 0
      border-right-color transparent

@media (max-width: 768px)
  .quest-item
    float none !important
    margin-left auto
    margin-right auto
    max-width 500px
    width 100%

.quest-item__img-container
  position relative
  color #fff
  min-height 150px
  img
    width 100%
    display block
    position: absolute;

.quest-item.no-gradient
  .quest-item__img-container
    &:after
      display none

.quest-item__icons
  position absolute
  z-index 1
  bottom 0
  padding 12px 2px 12px 12px
  color #fff
  .fr
    position relative
    bottom -3px
  .icon:hover
    color $orange-dark
    transition 0.2s

.quest-item__reviews
  position absolute
  left 20px
  bottom 20px
  text-align center
  >span
    position relative
    display inline-block
    padding 2px 5px
    margin-top 2px
    border-radius 3px
    min-width 30px
    font-size 14px
    text-align center
    background: $orange;
    color: #121314;
    &:after
      content: "";
      position: absolute;
      bottom: 0;
      left: 20%;
      transition:none;
      height: 0;
      margin: 0 0 -7px 0;
      border-width: 4px;
      border-style: solid;
      border-color: $orange transparent transparent $orange;

.quest-link
  color #fff
  display block
  padding-bottom 66.66%

.quest-restrictions
  padding 6px 12px
  background #ececec
  font-size 16px

.quest-age
  width 40px
  height 40px
  line-height 40px
  background $orange
  border-radius 50%
  text-align center
  position absolute
  right 12px
  top 12px
  font-size 16px
  color #000

.quest-people,
.quest-price
  line-height 40px

.item-info-wrap
  background #fff
  padding 15px 20px 5px

.map-item-info-wrap
  display block
  padding 10px
  >span
    display block

.item-name
  margin 0
  font-size 20px

  a
    text-decoration none
    color $black

.item-properties
  margin 15px 0
  li
    margin 10px 0
    span:first-child
      color $gray
    a
      color $black

@media only screen and (max-width: 1200px)
  .item-name
    font-size 20px