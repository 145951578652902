.phoneNumber
  cursor pointer
  color #0084ff
  font-size 150%
  padding 15px
  display block

  &:hover
    color $orange-text

  > span
    display block
    line-height 27px

  .phone-hidden-label
    font-size 16px
    font-weight 700

  &.shown
    line-height 54px
    font-size 170%

@media (min-width: 992px)
  a.phoneNumber
    text-decoration none
    color $black