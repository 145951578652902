.breadcrumbs
  z-index 2
  top 100%
  padding 8px 15px
  font-size 13px
  line-height 1.5
  color $gray
  background #f5f5f5
  border 1px solid #dbdbdb
  border-radius 0 0 5px 5px
  left 0
  margin 0
  display inline-block

  li
    position relative
    display inline-block
    margin-left 20px
    &:before
      content '/'
      position absolute
      top 0
      bottom 0
      left -14px
    &:first-child
      margin-left 0
      &:before
        display none

    a
      color $gray
      text-decoration underline
      &:hover
        color $gray-dark
        text-decoration none