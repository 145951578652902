.rating-stars
  display inline-block
  position relative
  i
    display inline-block
    margin 0 0 0 3px
    color $orange
    &:first-child
      margin 0
  &-unfilled
    i
      @extend .font-icon-star
  &-filled
    position absolute
    overflow hidden
    white-space nowrap
    i
      @extend .font-icon-star-filled
